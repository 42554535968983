import { createSignal, Show, splitProps, type JSX } from 'solid-js';

import styles from '~/components/player/Image.module.scss';

interface ImageProps {
  readonly sources: {
    readonly sizes: string;
    readonly src: string;
  }[];
  readonly alt: string;
  readonly class?: string;
  readonly classList?: Record<string, boolean | undefined>;
  readonly style?: string | JSX.CSSProperties;
  readonly displayFallback?: boolean;
}

export default function Image(props: ImageProps) {
  const [image, classes, rest] = splitProps(
    props,
    ['sources', 'alt', 'displayFallback'],
    ['class', 'classList'],
  );
  const imageProps = () => {
    // eslint-disable-next-line unicorn/prevent-abbreviations
    let src = 'about:blank';
    const srcset = image.sources
      .map(source => {
        src = source.src;
        if (source.sizes === 'any') {
          return '';
        }

        const [size] = source.sizes.split('x');
        return `${source.src} ${size}w`;
      })
      .filter(Boolean)
      .join(', ');

    return { src, srcset };
  };

  const classList = () => {
    const result: Record<string, boolean> = { [styles.container]: true };

    if (classes.class) {
      result[classes.class] = true;
    }

    return { ...result, ...classes.classList };
  };

  const [error, setError] = createSignal(false);

  return (
    <div classList={classList()}>
      <img
        {...imageProps()}
        classList={{ [styles.error]: error() }}
        {...rest}
        alt={image.alt}
        loading="lazy"
        onLoad={() => {
          setError(false);
        }}
        onError={() => {
          setError(true);
        }}
      />
      <Show when={image.displayFallback}>
        <div class={styles.placeholder}>
          <IconTablerMusic />
        </div>
      </Show>
    </div>
  );
}
